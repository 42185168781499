.showcase {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-image: linear-gradient(to bottom, transparent 0%, #23212a 100%),
    url("./images/UrbanTouch_MobileBackground-min.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 5.5rem;
}

.showcase__textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcase__titles {
  text-align: center;
}

.showcase__titles > h1 {
  font-size: 3.75rem;
  color: #f7f7f7;
  font-family: "B612", sans-serif;
  width: 80vw;
}

.showcase__titles > p {
  padding-left: 10px;
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #f7f7f7;
}

.showcase__shoppingButton {
  cursor: pointer;
  background: transparent;
  color: #f7f7f7;
  width: 20vw;
  font-size: 1.25rem;
  padding: 10px;
  border: 3px solid #23212a;
  border-radius: 30px;
  margin-top: 2rem;
  outline: none;
  transition: all 0.3s;
}

.showcase__shoppingButton:hover {
  opacity: 0.95;
  border-color: #f7f7f7;
}

@media only screen and (max-width: 900px) {
  .showcase__titles > h1 {
    font-size: 2.5rem;
  }

  .showcase__titles > p {
    font-size: 1.25rem;
  }

  .showcase__modelImage {
    left: 4%;
  }
}

@media only screen and (max-width: 768px) {
  .showcase {
    padding: 7rem 0 0 0;
    height: 70vh;
    background-image: linear-gradient(to bottom, transparent 0%, #23212a 100%),
      url("./images/UrbanTouch_MobileBackground-min.jpg");
    background-position: right;
    background-size: cover;
    background-repeat: none;
  }

  .showcase__textContent {
    z-index: 10;
  }

  .showcase__titles > h1 {
    font-size: 2rem;
    width: 100%;
    /* color: blue; */
    color: #fff;
  }

  .showcase__titles > p {
    display: none;
  }

  .showcase__imageContainer {
    display: flex;
    flex: 0;
    width: 0%;
    height: 40vh;
    margin-top: 0;
  }

  .showcase__modelImage {
    display: none;
  }

  .showcase__textContent {
    flex: 1;
  }

  .showcase__shoppingButton {
    width: 40vw;
    font-size: 1rem;
    padding: 0.5rem;
    margin-top: 2rem;
    background: none;
    border-radius: 8px;
    border: 2px solid;
    color: #fff;
  }
}
