.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  position: fixed;
  height: 12vh;
  width: 100%;
  z-index: 100;
}

.header.active {
  background: #f7f7f7;
  border-bottom: 2px solid #23212a;
}

.header__left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  height: 12vh;
  margin: 0;
  padding-top: 5px;
}

.header__list ul {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 2rem;
  padding: 0;
}

.header__link {
  color: black;
  text-decoration: none;
  font-weight: 600;
}

.header__link:hover {
  border-bottom: 2px solid black;
}

.header__listMobile {
  display: none;
}

/* Mobile */
.header__mobileMenu {
  display: none;
  align-items: center;
  justify-content: center;
  background: none;
  transform: scale(1.75);
  text-decoration: none;
  color: #23212a;
  position: absolute;
  top: 1.75rem;
  right: 1.75rem;
}

.header__mobileNav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  background: #23212a;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: -150%;
  transition: right 0.65s ease-in-out;
}

.header__mobileNav.active {
  right: 0;
}

.header__mobileNav > ul {
  list-style: none;
  margin-top: 2rem;
}

.header__mobileNavClose {
  color: #f7f7f7;
  font-size: 1.75rem;
  font-weight: 600;
  padding: 10px 15px;
  text-decoration: none;
}

.header__mobileNav .header__mobileLink {
  margin: 30px;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.75rem;
  color: #f7f7f7;
}

.header__mobileNav .header__mobileLink:hover {
  text-decoration: underline;
}

/* Cart */
.header__cart {
  padding-right: 4rem;
  transition: all 0.5s ease;
}

.header__cartIcon {
  cursor: pointer;
  transform: scale(1.15);
  transition: all 0.3s;
}

.header__cartIcon:hover {
  transform: scale(1.2);
}

.header__cartCount {
  margin-left: 0.5rem;
  font-weight: 600;
  opacity: 0.7;
}

.header__link {
  color: black;
  text-decoration: none;
}

.header__link:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 900px) {
  .header {
    justify-content: space-between;
    padding: 0;
    height: 10vh;
    width: 100%;
  }

  .header__list > ul {
    margin-top: 0.5rem;
  }

  .header__logo {
    padding-right: 0;
    position: relative;
    right: 1.5rem;
  }

  .header__cart {
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    position: fixed;
    background: transparent;
  }

  .header__list {
    display: none;
  }

  .header__mobileMenu {
    display: flex;
  }

  .header__cart {
    display: none;
  }
}

@media only screen and (min-width: 1600px) {
  .header {
    height: 8vh;
  }
}
