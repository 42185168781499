.accountPage {
  font-size: 1.25rem;
  display: block;
  background: #f7f7f7;
  background-image: linear-gradient(to bottom, transparent 0%, #23212a 100%);
  height: 100vh;
  padding-top: 10%;
}

.accountPage__message {
  text-align: center;
  padding: 5rem;
}

.accountPage__message span {
  color: blue;
}

.accountPage__link {
  text-decoration: none;
}

.accountPage__previousOrdersButton {
  display: block;
  border-radius: 10px;
  width: 50%;
  height: 50px;
  border: 1px solid black;
  margin: 10px auto;
  background: #23212a;
  color: #fff;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: bold;
  opacity: 0.85;
  transition: opacity 0.3s;
}

.accountPage__previousOrdersButton > a {
  text-decoration: none;
}

.accountPage__previousOrdersButton:hover {
  opacity: 1;
}

.accountPage > p {
  padding: 1rem 3rem;
  color: #f7f7f7;
  background: #23212a;
  margin: 4rem;
  margin-bottom: 0;
  display: none;
}

@media only screen and (max-width: 900px) {
  .accountPage {
    font-size: 1rem;
    height: fit-content;
  }

  .accountPage__message {
    padding: 4rem 1rem;
  }

  .accountPage__previousOrdersButton {
    height: fit-content;
    font-size: 1rem;
    padding: 10px 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .accountPage {
    font-size: 0.75rem;
  }

  .accountPage > p {
    font-size: 0.65rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 4px;
  }
}
