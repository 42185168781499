.orders {
  height: fit-content;
  width: 100%;
  background: #f7f7f7;
  padding: 10vh 3rem 1rem 3rem;
}

.orders > h1 {
  text-align: center;
}

@media (max-width: 900px) {
  .orders > h1 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 900px) {
  .orders {
    padding: 4.5rem 2rem 1rem 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .orders {
    padding: 4.5rem 1rem 1rem 1rem;
  }
}

@media only screen and (max-width: 1600px) {
  .orders {
    padding-top: 7%;
  }
}
