.coming {
  height: 95vh;
  background: url("./images/ComingSoonBkg-min.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding-bottom: 8rem;
  padding-top: 1.5rem;
}

.coming > h3 {
  font-size: 3.25rem;
}

.coming > p {
  font-size: 1.5rem;
  font-weight: bold;
  width: 30rem;
  margin-top: 15px;
}

@media only screen and (max-width: 900px) {
  .coming {
    height: 55vh;
  }

  .coming > h3 {
    font-size: 2.5rem;
  }

  .coming > p {
    font-size: 1rem;
    width: 20rem;
    margin-top: 10px;
  }
}
