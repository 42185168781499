.checkoutProduct {
  display: flex;
  margin: 20px auto;
  padding: 0 2rem;
}

.checkoutProduct__info {
  padding-left: 20px;
  padding-top: 2rem;
}

.checkoutProduct__info > button {
  background: #f7f7f7;
  cursor: pointer;
  border: 1.5px solid #23212a;
  background: none;
  border-radius: 2px;
  margin-top: 1.5rem;
  padding: 5px 15px;
  font-size: 0.9rem;
  font-weight: 700;
  color: #111;
}

.checkoutProduct__price {
  margin-top: 0.75rem;
  font-size: 1.25rem;
}

.checkoutProduct__image {
  object-fit: contain;
  height: 240px;
  border-radius: 10px;
}

.checkoutProduct__image > img {
  border-radius: 10px;
}

.checkoutProduct__title {
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .checkoutProduct {
    padding: 0 1rem;
  }
}
