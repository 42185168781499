*,
*::before,
*::after {
  box-sizing: border-box;
}

.app {
  margin: 0;
  padding: 0;
  background: #f7f7f7;
  background-size: cover;
  font-family: "Inter", sans-serif;
  height: 100%;
  width: 100%;
}
