.footer {
  background: #23212a;
  color: #fff;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.footer__link {
  text-decoration: none;
  color: #fff;
  margin: 10px 0;
  font-size: 0.9rem;
  transition: all 0.3s;
}

.footer__link:hover {
  color: #f17c4d;
}

.footer__departments,
.footer__account {
  margin: 0 2rem;
  width: 15vw;
}

.footer__departments > h3,
.footer__account > h3 {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.footer__departments > ul,
.footer__account > ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 0;
}

.footer__departments > li,
.footer__account > li {
  text-align: left;
  margin: 10px 0;
}

@media only screen and (max-width: 900px) {
  .footer__departments,
  .footer__account {
    width: 20vw;
  }
}
