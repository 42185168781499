.women {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.5rem 2.5rem 2rem 2.5rem;
  border-radius: 10px;
  background: #f7f7f7;
}

.women__topsContainer,
.women__pantsContainer,
.women__shoesContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  margin: 20px 50px;
  margin-bottom: 2.5rem;
}

.women__tops > h1,
.women__pants > h1,
.women__shoes > h1 {
  border-bottom: 1px solid #a7a7a7;
  padding: 10px;
  margin: 30px 3rem;
}

.women__tops > h1 {
  margin-top: 10px;
}

.women__shoes {
  margin-bottom: 3rem;
}

.women__top,
.women__pant,
.women__shoe {
  padding: 10px;
  margin: 20px 0;
  object-fit: contain;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 900px) {
  .women {
    margin: 1rem;
  }

  .women__topsContainer,
  .women__pantsContainer,
  .women__shoesContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .women__tops > h1,
  .women__pants > h1,
  .women__shoes > h1 {
    margin: 30px 2rem 40px 2rem;
  }

  .rowTop {
    margin-top: 0rem;
  }

  .rowBottom {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 600px) {
  .women {
    margin: 0;
    padding-top: 3.5rem;
  }

  .women__topsContainer,
  .women__pantsContainer,
  .women__shoesContainer {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  .women__tops > h1,
  .women__pants > h1,
  .women__shoes > h1 {
    margin: 20px 1rem;
  }

  .women__pants > h1,
  .women__shoes > h1 {
    padding-top: 2rem;
  }

  .women__top,
  .women__pant,
  .women__shoe {
    margin-bottom: 5rem;
  }

  .women__top:last-child,
  .women__pant:last-child,
  .women__shoe:last-child {
    margin-bottom: 0;
  }

  .rowTop,
  .rowBottom {
    margin-top: 1.75rem;
  }
}
